.privacy-content {
  text-align: left;
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  padding-bottom: 20px;
}

.privacy-title {
  color: #101828;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 10px;
}
.privacy-sub-title {
  color: #101828;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
