.expand-title {
  margin-top: 10px;
}

.expand-content {
  margin-bottom: 40px;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  border-bottom: 1px solid #dee2e6 !important;
}
